<template>
  <div>
    <slot name="header" />
    <div class="container mx-auto pb-12 max-w-3xl">
      <div>
        <div
          class="my-8"
          v-for="(option,index) in data.options"
          :key="index">
          <div class="font-bold mb-2">
            {{ option.title }}
          </div>
          <div v-if="option.type == 'selectOptionField'">
            <input-select
              :options="option.options"
              option-value="title"
              option-key="title"
              placeholder="Velg et alternativ"
              v-model="option.model" />
          </div>
          <div v-else-if="option.type == 'radioOptionField'">
            <radio-list
              :options="option.options"
              option-value="title"
              option-key="title"
              v-model="option.model" />
          </div>
          <div v-else-if="option.type == 'checkListOptionField'">
            <checkbox-list
              :options="option.options"
              option-value="title"
              option-key="title"
              v-model="option.model" />
          </div>
          <div v-else-if="option.type == 'inputOptionField'">
            <input-field
              v-model="option.model"
              type="text"
              :label="option.title"
              class="mb-4" />
          </div>
        </div>
      </div>
    </div>

    <slot :is-valid="true" />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>
